<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Delete User
        </v-card-title>
        <v-card-text>
            <p v-if="loading == false && sections.length == 0">
                No user-related modules were found.
            </p>
            <p v-if="loading == false && sections.length > 0">
                Before proceeding with this operation, the user must be replaced
                in the following modules:
            </p>
            <v-skeleton-loader
                class="mx-auto"
                max-width="700"
                type="table-tbody"
                v-if="loading && sections.length == 0"
            ></v-skeleton-loader>
            <v-expansion-panels v-else v-model="panel" multiple>
                <v-expansion-panel
                    v-for="(section, index) of sections"
                    :key="index"
                >
                    <v-expansion-panel-header class="text-capitalize"
                        >{{ section.name }} ({{ section.qty }})
                        <v-spacer />
                        <v-select
                            :items="users"
                            v-model="section.generalUser"
                            placeholder="New User *"
                            hide-details
                            class="py-0 my-0 mx-4"
                            item-text="name"
                            @change="autocompleteSection(section)"
                            style="max-width: 200px !important;"
                        >
                        </v-select>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-data-table
                            :headers="
                                section.name == 'projects' ||
                                section.name == 'quotes'
                                    ? projectHeaders
                                    : headers
                            "
                            :items="section.items"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:[`item.newUser`]="{ item }">
                                <v-select
                                    :items="users"
                                    v-model="item.newUser"
                                    placeholder="user *"
                                    hide-details
                                    class="py-0 my-0"
                                    item-text="name"
                                >
                                </v-select>
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                class="mb-2 mr-2"
                :disabled="validateNewUsers"
                :loading="loading"
                @click="replaceUser"
            >
                DELETE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'ReplaceUser',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        users: [],
        loading: false,
        panel: [],
        sections: [],
        projectHeaders: [
            {
                text: 'ID',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NAME',
                value: 'name',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ROLE',
                value: 'role',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NEW USER',
                value: 'newUser',
                align: 'center',
                sortable: false,
                width: '200',
            },
        ],
        headers: [
            {
                text: 'ID',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ROLE',
                value: 'role',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NEW USER',
                value: 'newUser',
                align: 'center',
                sortable: false,
                width: '200',
            },
        ],
    }),
    computed: {
        validateNewUsers() {
            for (const section of this.sections) {
                if (section.items && section.items.length > 0) {
                    for (const item of section.items) {
                        if (!item.newUser) {
                            return true
                        }
                    }
                }
            }
            return false
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.sections = await API.userProcesses(this.user.id)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async replaceUser() {
            try {
                this.loading = true
                for (const section of this.sections) {
                    for (const item of section.items) {
                        const newUser = this.users.find(
                            user => user.name == item.newUser
                        )
                        item.newUser = newUser
                    }
                }
                await API.replaceAndDeleteUser(this.user.id, this.sections)
                this.$emit('deleteUser')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        autocompleteSection(section) {
            const index = this.sections.findIndex(s => s.name == section.name)
            for (const item of this.sections[index].items) {
                item.newUser = section.generalUser
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
